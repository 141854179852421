export const API_ENDPOINTS = {
  CORE: {
    AUTH: {
      CONNECT: "/auth/staff/login",
      LOGOUT: "/staff/logout",
      PROFILE: "/staff/profile",
      INVITATIONS: "/auth/staff/invite/:inviteCode",
      OTPRESET: "/auth/staff/reset-two-factor/:token",
    },
  },
  CARDS: {
    ROOT: "/cards",
    BY_ID: "/cards/:cardId",
    TRANSACTIONS_DECLINED: "/cards/:cardId/declines",
    TRANSACTIONS: "/cards/:cardId/transactions",
    SYNC_TRANSACTIONS: "/cards/:cardId/transactions/sync",
    REFILLS: "/cards/:cardId/refills",
  },
  DISCOUNTS: {
    ROOT: "/discounts",
    BY_ID: "/discounts/:discountId",
  },
  USERS: {
    ROOT: "/users",
    BY_ID: "/users/:userId",
    CARDS: "users/:userId/cards",
    REFILLS: "users/:userId/refills",
    PURCHASES: "users/:userId/purchases",
  },
  RATES: {
    ROOT: "/rates",
    BY_ID: "/rates/:rateId",
  },
  PURCHASES: {
    ROOT: "/purchases",
    BY_ID: "/purchases/:purchasedId",
    RELOAD: "/purchases/:purchasedId/reload",
  },
  REFILLS: {
    ROOT: "/refills",
    BY_ID: "/refills/:id",
    RELOAD: "/refills/:id/reload",
  },

  STAFFS: {
    ROOT: "/staffs/members",
    BY_ID: "/staffs/members/:staffId",
    INVITATIONS: {
      ROOT: "/staffs/invites",
      BY_ID: "/staffs/invites/:staffId",
      RESEND_INVITATIONS: "/staffs/invites/:staffId/resend",
    },

    ACTIVITIES: "/staffs/activities",
    STAFF: {
      ACTIVITY: "/staffs/activities/:staffId",
      ACTIVITIES: "/staffs/members/:staffId/activities",
    },
    AUTH: {
      RESET_STAFF_OTP: "/staffs/members/:staffId/reset-two-factor",
    },
  },
  TRANSACTIONS: {
    ROOT: "/transactions",
  },
};
