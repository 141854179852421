import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost, usePut } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  DiscountCode,
  DiscountCodePayload,
  PaginatedDiscountCodes,
  PaginatedUsers,
} from "utilities";

export const useGetDiscountCodes = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedDiscountCodes>({
    url: API_ENDPOINTS.DISCOUNTS.ROOT,
    params,
  });
};

export const useGetDiscountCode = ({ discountId }: { discountId: string }) =>
  useGet<DiscountCode>({
    url: formatPath(API_ENDPOINTS.DISCOUNTS.BY_ID, { discountId }),
  });

export const useUpdateDiscountCodeEnable = ({
  discountId,
}: {
  discountId: string;
}) => {
  return usePut<Partial<DiscountCode>, DiscountCode>({
    url: formatPath(API_ENDPOINTS.DISCOUNTS.BY_ID, { discountId }),
  });
};
export const useUpdateDiscountCode = ({
  discountId,
}: {
  discountId: string;
}) => {
  return usePut<Partial<DiscountCodePayload>, DiscountCode>({
    url: formatPath(API_ENDPOINTS.DISCOUNTS.BY_ID, { discountId }),
  });
};
export const useAddDiscountCode = () =>
  usePost<DiscountCodePayload, DiscountCode>({
    url: API_ENDPOINTS.DISCOUNTS.ROOT,
  });

export const useGetAllUsers = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedUsers>({ url: API_ENDPOINTS.USERS.ROOT, params });
};
