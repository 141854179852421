import { API_ENDPOINTS } from "@/configs";
import { useGet } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  PaginatedCards,
  PaginatedPurchases,
  PaginatedRefills,
  PaginatedUsers,
  User,
} from "utilities";

export interface UserWithMessage extends User {
  message: string;
}

export const useGetUser = (userId: string) =>
  useGet<User>({ url: formatPath(API_ENDPOINTS.USERS.BY_ID, { userId }) });
export const useGetUserInformation = (userId: string) =>
  useGet<User>({ url: formatPath(API_ENDPOINTS.USERS.BY_ID, { userId }) });

export const useGetUsers = ({
  page = 1,
  search,
  initialData,
  status = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedUsers;
  status?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status: status !== "all" ? status : "",
  });
  return useGet<PaginatedUsers>({
    url: API_ENDPOINTS.USERS.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetUserCards = (
  userId: string,
  {
    page,
    search,
    initialData,
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedCards;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedCards>({
    url: formatPath(API_ENDPOINTS.USERS.CARDS, { userId }),
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetUserRefills = (
  userId: string,
  {
    page,
    search,
    initialData,
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedRefills;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedRefills>({
    url: formatPath(API_ENDPOINTS.USERS.REFILLS, { userId }),
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetUserPurchases = (
  userId: string,
  {
    page,
    search,
    initialData,
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedPurchases;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedPurchases>({
    url: formatPath(API_ENDPOINTS.USERS.PURCHASES, { userId }),
    params,
    queryOptions: {
      initialData,
    },
  });
};
