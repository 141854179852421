import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import {
  Card,
  PaginatedCardTransactions,
  PaginatedCards,
  PaginatedDeclinedTransactions,
  PaginatedRefills,
  TransactionType,
} from "utilities";

export const useGetCards = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedCards>({ url: API_ENDPOINTS.CARDS.ROOT, params });
};

export const useGetCardsDeclinedTransactions = (
  cardId: string,
  {
    page,
    search,
  }: {
    page?: number;
    search?: string;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedDeclinedTransactions>({
    url: formatPath(API_ENDPOINTS.CARDS.TRANSACTIONS_DECLINED, { cardId }),
    params,
  });
};

export const useGetCard = (cardId: string) => {
  return useGet<Card>({
    url: formatPath(API_ENDPOINTS.CARDS.BY_ID, { cardId }),
  });
};

export const useGetCardTransactions = (
  cardId: string,
  {
    page,
    search,
    type,
  }: {
    page?: number;
    search?: string;
    type?: TransactionType;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
    type,
  });
  return useGet<PaginatedCardTransactions>({
    url: formatPath(API_ENDPOINTS.CARDS.TRANSACTIONS, { cardId }),
    params,
  });
};

export const useSyncTransactions = (cardId: string) => {
  return usePost({
    url: formatPath(API_ENDPOINTS.CARDS.SYNC_TRANSACTIONS, { cardId }),
  });
};
export const useGetCardRefills = (
  cardId: string,
  {
    page,
    search,
  }: {
    page?: number;
    search?: string;
  },
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useGet<PaginatedRefills>({
    url: formatPath(API_ENDPOINTS.CARDS.REFILLS, { cardId }),
    params,
  });
};
