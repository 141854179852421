import { closeModal, useForm } from "@axazara/raiton-molecules";
import { DateRange, DiscountCodePayload, SimplifiedUser } from "utilities";
import { useAddDiscountCode, useGetAllUsers } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINTS } from "@/configs";
import {
  showErrorNotification,
  showSuccessNotification,
  useFilterDropdownStyles,
} from "@/utils";
import {
  Avatar,
  Box,
  Button,
  DatePickerInput,
  Group,
  InputField,
  Menu,
  Select,
  Text,
  UnstyledButton,
} from "@axazara/raiton-atoms";
import {
  ArrowDownOutline,
  Calendar2Outline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import React from "react";
import { classNames, useDebouncedValue } from "@axazara/raiton-utils";

export function AddDiscountCode() {
  const queryClient = useQueryClient();
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);

  const [opened, setOpened] = React.useState(false);

  const { classes } = useFilterDropdownStyles({
    opened: opened,
    width: "100%",
  });

  const [searchQuery, setSearchQuery] = React.useState("");

  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const [userFilter, setUserFilter] = React.useState<SimplifiedUser | null>();

  const { data: users } = useGetAllUsers({ page: 1, search: debouncedSearch });

  const discountCodeType = [
    { label: "PERCENTAGE", value: "percentage" },
    { label: "FIXED", value: "fixed" },
  ];

  const addDiscountCodeForm = useForm<DiscountCodePayload>({
    initialValues: {
      code: "",
      type: "",
      value: "",
      valid_from: "",
      expires_at: "",
      use_limit: "",
      limit_per_user: "",
    },
  });

  const addDiscountCodeMutation = useAddDiscountCode();

  async function handleCreateDiscountCode(values: DiscountCodePayload) {
    try {
      const response = await addDiscountCodeMutation.mutateAsync(values);
      queryClient.invalidateQueries([
        API_ENDPOINTS.DISCOUNTS.ROOT,
        { page: 1 },
      ]);
      showSuccessNotification((response as any)?.message);

      closeModal("");
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  const userItems = users?.data?.map((item) => (
    <Menu.Item
      className={userFilter?.id === item?.id ? "bg-neutral-90" : ""}
      icon={<Avatar size={24} src={item?.profile_photo_url} />}
      onClick={() => {
        addDiscountCodeForm.setFieldValue("user_id", item?.id);
        setUserFilter(item);
      }}
      key={item?.id}
    >
      {item?.first_name} {item?.last_name}
    </Menu.Item>
  ));

  return (
    <Box>
      <Box
        component="form"
        onSubmit={addDiscountCodeForm.onSubmit(handleCreateDiscountCode)}
      >
        <Box className="space-y-6 my-8">
          <InputField
            required
            size="xs"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Code"
            placeholder="Enter discount code "
            {...addDiscountCodeForm.getInputProps("code")}
          />

          <Select
            {...addDiscountCodeForm.getInputProps("type")}
            required
            label="Type"
            placeholder="Type"
            data={discountCodeType as any}
            optionResolver={(type: any) => ({
              label: type.label,
              value: type.value,
            })}
            classNames={{ label: "mb-3 text-p-01-semibold text-dark" }}
            type="text"
            size="xs"
            className="mb-5"
            radius={12}
            itemComponent={({ label, value, ...others }) => (
              <Box className="py-2" key={value} {...others}>
                <Box className="flex flex-row items-center ">
                  <Text className="text-p-01-medium">{label}</Text>
                </Box>
              </Box>
            )}
            styles={(theme) => ({
              input: {
                borderColor: theme.colors.neutral[6],
              },
            })}
          />

          <InputField
            required
            size="xs"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Value"
            placeholder="Enter discount code name"
            {...addDiscountCodeForm.getInputProps("value")}
          />
          <InputField
            size="xs"
            placeholder="Enter limit "
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Limite d'utilisation "
            {...addDiscountCodeForm.getInputProps("use_limit")}
          />
          <InputField
            size="xs"
            placeholder="Enter limit per user"
            classNames={{
              input: "rounded-xl",
              label: "mb-3 text-p-01-semibold text-dark",
            }}
            label="Limite d'utilisation par user "
            {...addDiscountCodeForm.getInputProps("limit_per_user")}
          />

          <Box className="w-full">
            <Menu
              onOpen={() => setOpened(true)}
              onClose={() => setOpened(false)}
              radius="md"
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton className={`${classes.control} space-x-4`}>
                  {opened ? (
                    <InputField
                      onClick={(e) => e.stopPropagation()}
                      styles={() => ({})}
                      classNames={{
                        input:
                          "rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none  h-[38px] min-h-[38px]",
                      }}
                      value={searchQuery}
                      className="w-full"
                      iconWidth={12}
                      size="xs"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      icon={<SearchNormal1Outline className="w-3 h-3" />}
                    />
                  ) : userFilter ? (
                    <>
                      <Group spacing="xs" className="w-full">
                        {userFilter?.profile_photo_url && (
                          <Avatar
                            size={24}
                            src={userFilter?.profile_photo_url}
                          />
                        )}
                        <span
                          className={`${classes.label} text-ellipsis overflow-hidden whitespace-nowrap w-16`}
                        >
                          {userFilter?.first_name} {userFilter?.last_name}
                        </span>
                      </Group>
                      {userFilter && (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-50 mr-2"
                          onClick={() => {
                            setUserFilter(null);
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <Text
                      className="text-footer"
                      styles={() => ({ root: { fontSize: 12 } })}
                    >
                      Select user
                    </Text>
                  )}
                  <ArrowDownOutline
                    className={classNames(
                      classes.icon,
                      "w-4 h-4 text-neutral-40",
                    )}
                  />
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown style={{ maxHeight: "300px", overflow: "auto" }}>
                {userItems}
              </Menu.Dropdown>
            </Menu>
          </Box>

          <DatePickerInput
            // @ts-ignore
            placeholder="Choise valide and expire date"
            size="xs"
            type="range"
            clearable
            icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
            value={selectedDateRange}
            numberOfColumns={2}
            styles={(theme) => ({
              root: {
                "> div": {
                  borderColor: theme.colors.neutral[8],
                },
              },
            })}
            onChange={(value) => {
              const [start, end] = value;
              setSelectedDateRange(value);
              if (start) {
                addDiscountCodeForm.setFieldValue(
                  "valid_from",
                  `${start.getFullYear()}-${
                    start.getMonth() + 1
                  }-${start.getDate()}`,
                );
              }
              if (end) {
                addDiscountCodeForm.setFieldValue(
                  "expires_at",
                  `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
                );
              }
            }}
            allowSingleDateSelectionInRange
            rightSection={
              selectedDateRange[1] ? null : (
                <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
              )
            }
          />
        </Box>

        <Box className="flex justify-center">
          <Button
            type="submit"
            size="xs"
            disabled={!addDiscountCodeForm.isDirty()}
            loading={addDiscountCodeMutation.isLoading}
            className="text-caption-semibold"
          >
            Add discount code
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
