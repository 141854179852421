import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import { PaginatedRefills, Refill } from "utilities";

export const useGetRefills = ({
  page,
  search,
  initialData,
  status = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedRefills;
  status?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status: status !== "all" ? status : "",
  });
  return useGet<PaginatedRefills>({
    url: API_ENDPOINTS.REFILLS.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetRefill = (id: string) => {
  return useGet<Refill>({
    url: formatPath(API_ENDPOINTS.REFILLS.BY_ID, { id }),
  });
};
export const useReloadRefill = (id: string) => {
  return usePost<Refill, any>({
    url: formatPath(API_ENDPOINTS.REFILLS.RELOAD, { id }),
  });
};
