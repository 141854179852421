import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { formatPath } from "@axazara/shuriken";
import { CardPurchase, PaginatedPurchases } from "utilities";

export const useGetPurchases = ({
  page,
  search,
  initialData,
  status = "",
  brand = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedPurchases;
  status?: string;
  brand?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status: status !== "all" ? status : "",
    brand: brand !== "all" ? brand : "",
  });
  return useGet<PaginatedPurchases>({
    url: API_ENDPOINTS.PURCHASES.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useGetPurchase = (purchasedId: string) => {
  return useGet<CardPurchase>({
    url: formatPath(API_ENDPOINTS.PURCHASES.BY_ID, { purchasedId }),
  });
};

export const useReloadPurchase = (purchasedId: string) => {
  return usePost<CardPurchase, any>({
    url: formatPath(API_ENDPOINTS.PURCHASES.RELOAD, { purchasedId }),
  });
};
