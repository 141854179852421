import { API_ENDPOINTS } from "@/configs";
import { useGet, usePost, usePut } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { PaginatedRates, RatePayload, RateResponse } from "utilities";
import { useState, useEffect } from "react";
import { formatPath } from "@axazara/shuriken";

export const useGetRates = ({
  page,
  search,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedRates;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });

  return useGet<PaginatedRates>({
    url: API_ENDPOINTS.RATES.ROOT,
    params,
    queryOptions: {
      initialData,
    },
  });
};

export const useCreateRate = () => {
  return usePost<RatePayload, RateResponse>({ url: API_ENDPOINTS.RATES.ROOT });
};
export const useUpdateRate = (rateId: string) => {
  return usePut<Partial<RatePayload>, RateResponse>({
    url: formatPath(API_ENDPOINTS.RATES.BY_ID, { rateId }),
  });
};
export function useCorrectionRateCalculator(
  correctionRateValue: any,
  baseRate: any,
) {
  const [correctionRate, setCorrectionRate] = useState(null);

  useEffect(() => {
    const calculateCorrectionRate = () => {
      const A = baseRate * (correctionRateValue / 100);
      return A + baseRate;
    };

    const result = calculateCorrectionRate();
    setCorrectionRate(result);
  }, [correctionRateValue, baseRate]);

  return correctionRate;
}
